import PropTypes from 'prop-types';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

import Avatar from 'shopper/components/Avatar';

import UserAvatar from 'components/UserAvatar';

import { isFirstItem, isLastItem } from 'lib/array';

const MAX_ALLOWED_DISPLAYED_AUTHORS = 3;
const AVATAR_WIDTH_BY_SIZE = {
  size1: 72,
  size2: 64,
  size3: 56,
  size4: 40,
  size5: 32,
  size6: 24,
};

const moveFirstAuthorToLast = (authors, firstAuthorId) =>
  authors
    .filter((a) => a.id !== firstAuthorId)
    .concat(authors.find((a) => a.id === firstAuthorId))
    .filter(Boolean);

const sliceAuthorsByDisplaySize = (authors) =>
  authors.length > MAX_ALLOWED_DISPLAYED_AUTHORS
    ? authors.slice(MAX_ALLOWED_DISPLAYED_AUTHORS * -1)
    : authors;

const getAuthorsSurplusSize = (authors) =>
  Math.max(authors.length - MAX_ALLOWED_DISPLAYED_AUTHORS, 0);

const getContainerWidth = (totalOfAuthors, size) => {
  const avatarWidth = AVATAR_WIDTH_BY_SIZE[size];
  const gapX = 8;

  return avatarWidth * totalOfAuthors - gapX * totalOfAuthors + gapX;
};

const Authors = ({
  authors,
  className,
  firstAuthorId,
  size = 'size6',
  onClick,
}) => {
  const isPopular = authors.length > MAX_ALLOWED_DISPLAYED_AUTHORS;
  const authorsSurplusSize = getAuthorsSurplusSize(authors);
  const sortedAuthors = sliceAuthorsByDisplaySize(
    moveFirstAuthorToLast(authors, firstAuthorId)
  );
  const WrapperTag = onClick ? 'button' : 'div';

  return (
    <WrapperTag
      className={twJoin('flex items-center', className)}
      style={{
        width: getContainerWidth(sortedAuthors.length, size),
      }}
      onClick={onClick}
    >
      {sortedAuthors.map(({ id, name, photo }, i) => (
        <UserAvatar
          key={id}
          className={twJoin(
            i === 1 && '-left-2',
            isLastItem(sortedAuthors, i) && !isFirstItem(i) && '-left-3'
          )}
          description={`Autor ${name}`}
          loading="lazy"
          size={size}
          userPhoto={photo}
        />
      ))}
      {isPopular && (
        <Avatar
          className="-left-4"
          counter={authorsSurplusSize}
          description={`Há também outros ${authorsSurplusSize} autores`}
          size={size}
        />
      )}
    </WrapperTag>
  );
};

Authors.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  firstAuthorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

export default memo(Authors);
