import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import useQueryClient from 'hooks/useQueryClient';

import { sendEvent } from 'lib/gtag';
import { offerQueryKeys, userQueryKeys } from 'lib/queryKeys';

import { useUser } from 'providers/UserProvider';

import { getUserOfferLikes, postUserOfferLike } from 'services/offer';

const LIKE_ACTION = 'like';

const LikeButton = ({
  className,
  gaEventCategory,
  ghost = false,
  likesCount: serverLikesCount,
  offerId,
  size = 'size3',
  type = 'secondary',
}) => {
  const queryClient = useQueryClient();
  const { isLoggedIn, runCallbackIfLoggedIn } = useUser();
  const [isLiked, setLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(() => {
    const stored = queryClient.getQueryData(
      offerQueryKeys.likesCount({ offerId })
    );

    return stored || serverLikesCount;
  });

  const { data: userLikedOffers } = useQuery({
    queryKey: userQueryKeys.offerLikes(),
    queryFn: ({ signal }) => getUserOfferLikes({ signal }),
    enabled: isLoggedIn,
  });

  const { mutate: mutateAddLike } = useMutation({
    mutationFn: () => postUserOfferLike({ offerId }),
    onSuccess: ({ action }) => {
      queryClient.setQueryData(userQueryKeys.offerLikes(), (likedOffers) =>
        action === LIKE_ACTION
          ? [...likedOffers, offerId]
          : likedOffers.filter((likedOfferId) => likedOfferId !== offerId)
      );

      sendEvent({
        category: gaEventCategory,
        action: action === LIKE_ACTION ? 'like_set' : 'like_unset',
      });
    },
  });

  useEffect(() => {
    if (!userLikedOffers) {
      return;
    }

    setLiked(userLikedOffers.some((likedOfferId) => likedOfferId === offerId));
  }, [userLikedOffers]);

  const onLike = useCallback(
    (event) => {
      event.preventDefault();

      runCallbackIfLoggedIn(() => {
        setLiked((prevIsLiked) => {
          queryClient.setQueryData(
            offerQueryKeys.likesCount({ offerId }),
            (storedLikesCount) => {
              const count = storedLikesCount ?? likesCount;

              return prevIsLiked ? count - 1 : count + 1;
            }
          );

          setLikesCount((prevLikesCount) =>
            prevIsLiked ? prevLikesCount - 1 : prevLikesCount + 1
          );

          return !prevIsLiked;
        });

        mutateAddLike();
      });
    },
    [runCallbackIfLoggedIn]
  );

  return (
    <Button
      aria-label={isLiked ? 'Remover curtida' : 'curtir oferta'}
      className={className}
      iconLeft={<Icon name={isLiked ? 'like-filled' : 'like'} />}
      size={size}
      type={isLiked ? type : ghost ? `${type}-ghost` : `${type}-stroke`}
      onClick={onLike}
    >
      {likesCount}
    </Button>
  );
};

LikeButton.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
  ghost: PropTypes.bool,
  likesCount: PropTypes.number.isRequired,
  offerId: PropTypes.number.isRequired,
  size: PropTypes.string,
};

export default LikeButton;
